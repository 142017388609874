import { sortBy } from "lodash";
import pDebounce from "p-debounce";

import {
  AddonInfo,
  EpicDetails,
  IssueTransition,
  JiraBoard,
  JiraContext,
  JiraField,
  JiraFlagType,
  JiraGroup,
  JiraIssue,
  JiraJqlValidation,
  JiraProject,
  JiraSprint,
  JiraStatus,
  JiraUser,
  Users,
} from "../types";
import { chunkArray, getErrorMessage } from "../utils";

const MAX_RESULTS = 100;
const MAX_REFERENCE_ISSUES = 8;

interface JiraRequest {
  xhr: XMLHttpRequest;
}

function handleJiraRequestMessage(xhr: XMLHttpRequest, fields: string[] = []) {
  try {
    const json = JSON.parse(xhr.responseText) as { errorMessages: string[]; errors: { [key: string]: string } };
    for (const field of fields) {
      if (json?.errors?.[field]) {
        return json.errors[field];
      }
    }
    if (json.errorMessages) {
      return json.errorMessages.join("\n");
    }
    return xhr.responseText;
  } catch (e) {
    return `Error: ${xhr.responseText}`;
  }
}

export class JiraApiError extends Error {
  status: number;

  constructor(error: unknown, fields: string[] = []) {
    const jiraRequest = error as JiraRequest;

    const message = handleJiraRequestMessage(jiraRequest.xhr, fields);
    super(message);

    this.name = "JiraApiError";
    this.status = jiraRequest.xhr.status;
  }
}

export const getJiraToken = async () => AP.context.getToken();
export const getJiraContext = () => AP.context.getContext() as Promise<JiraContext>;
export const getUserLocale: () => Promise<string> = () =>
  new Promise((resolve) => AP.user.getLocale((locale) => resolve(locale)));

export const getJiraLocation: () => Promise<string> = () => new Promise((resolve) => AP.getLocation(resolve));

export async function getUsersByIds(accountIds: string[], maxResults = 20) {
  const ids = accountIds.map((id) => ["accountId", id]);
  const params = [...ids, ["maxResults", maxResults.toString()]];
  const query = new URLSearchParams(params).toString();

  const result = await AP.request(`/rest/api/3/user/bulk?${query}`);
  return JSON.parse(result.body) as { values: JiraUser[] };
}

export async function getUsers(accountIds: string[], maxResults: number = 20): Promise<Users> {
  try {
    const ids = chunkArray(accountIds, maxResults);
    const users = await Promise.all(
      ids.map(async (batch) => {
        const { values } = await getUsersByIds(batch, maxResults);
        return values;
      }),
    );

    return users.flat().reduce<{ [key: string]: JiraUser }>((all, user) => {
      if (user) {
        all[user.accountId] = user;
      }

      return all;
    }, {});
  } catch (err) {
    console.error("Error while fetching users by accountIds", getErrorMessage(err));
    return {};
  }
}

export function showFlag(title: string, message: string, type: JiraFlagType, autoClose = true) {
  AP.flag.create({
    title: title,
    body: message,
    type: type,
    close: autoClose ? "auto" : undefined,
  });
}

export const debouncedShowFlag = pDebounce(showFlag, 1000, { before: true });

export async function getFields() {
  const result = await AP.request("/rest/api/3/field");
  return JSON.parse(result.body) as JiraField[];
}

export async function getJiraGroups(query?: string) {
  const result = await AP.request(`/rest/api/3/groups/picker?query=${query}`);
  return JSON.parse(result.body) as { total: number; groups: JiraGroup[] };
}

export async function getJiraBoards(query?: string) {
  const result = await AP.request(`/rest/agile/1.0/board?name=${query}`);
  return JSON.parse(result.body) as { total: number; values: JiraBoard[] };
}

export async function getBoardById(boardId: string) {
  const result = await AP.request(`/rest/agile/1.0/board/${boardId}`);
  return JSON.parse(result.body) as JiraBoard;
}

export async function getJiraSprints(boardId: string) {
  const result = await AP.request(`/rest/agile/1.0/board/${boardId}/sprint?state=active,future`);
  return JSON.parse(result.body) as { total: number; values: JiraSprint[] };
}

export async function getJiraSprintById(sprintId: string) {
  const result = await AP.request(`/rest/agile/1.0/sprint/${sprintId}`);
  return JSON.parse(result.body) as JiraSprint;
}

export async function getAllJiraIssuesBySprintId(sprintId: string) {
  let isFinished = false;
  let offset = 0;
  let result = [] as JiraIssue[];

  while (!isFinished) {
    const response = await AP.request(`/rest/agile/1.0/sprint/${sprintId}/issue?startAt=${offset}`);
    const { total, issues } = JSON.parse(response.body) as { total: number; issues: JiraIssue[] };
    result = [...result, ...issues];
    offset += issues.length;
    isFinished = result.length >= total;
  }

  return { issues: result };
}

export async function getGroupsBulk(ids: string[] = []) {
  const query = ids.map((id) => `groupId=${id}`).join("&");
  const result = await AP.request(`/rest/api/3/group/bulk?${query}`);
  return JSON.parse(result.body) as { total: number; values: JiraGroup[] };
}

export async function setAddonProperty<T>(addonKey: string, key: string, data: T) {
  return AP.request({
    url: `/rest/atlassian-connect/1/addons/${addonKey}/properties/${key}`,
    type: "PUT",
    data: JSON.stringify(data),
    contentType: "application/json",
  }).then(() => data);
}

export async function getAddonProperty<T>(addonKey: string, key: string, defaultValue?: T) {
  try {
    const result = await AP.request({
      url: `/rest/atlassian-connect/1/addons/${addonKey}/properties/${key}`,
      type: "GET",
      contentType: "application/json",
    });
    const property = JSON.parse(result.body) as {
      key: string;
      value: T;
    };
    return property.value;
  } catch (error) {
    if (defaultValue !== undefined) {
      return setAddonProperty<T>(addonKey, key, defaultValue);
    }
    throw error;
  }
}

type TimeTrackingValue = { originalEstimate?: string; remainingEstimate?: string };
export type EstimationValue = string | number | TimeTrackingValue | { value: string };

export async function updateIssueField(
  key: string,
  fields: {
    [key: string]: EstimationValue;
  } = {},
) {
  return AP.request({
    url: `/rest/api/3/issue/${key}`,
    type: "PUT",
    data: JSON.stringify({ fields }),
    contentType: "application/json",
  }).catch((err) => {
    throw new JiraApiError(err, Object.keys(fields));
  });
}

export async function addIssueComment(key: string, body: string) {
  return AP.request({
    url: `/rest/api/2/issue/${key}/comment`,
    type: "POST",
    data: JSON.stringify({ body }),
    contentType: "application/json",
  }).catch((err) => {
    throw new JiraApiError(err, ["comment"]);
  });
}

export async function getAddonInfo(addonKey: string) {
  return AP.request({
    url: `/rest/atlassian-connect/1/addons/${addonKey}`,
  }).then((res) => {
    const { license, version } = JSON.parse(res.body) as AddonInfo;
    return {
      license,
      version,
    };
  });
}

export async function searchUsers(query: string): Promise<JiraUser[]> {
  try {
    const result = await AP.request(`/rest/api/3/user/search?query=${query}`);
    return JSON.parse(result.body) as JiraUser[];
  } catch (err) {
    console.error("Failed to fetch user picker data", err);
    return [];
  }
}

export async function getIssuesByIds(ids: string[], additionalFields: string[] = []) {
  const fields = [
    "issuetype",
    "priority",
    "summary",
    "parent",
    "project",
    "status",
    "assignee",
    "description",
    "attachment",
    "comment",
  ].concat(additionalFields);

  const chunks = chunkArray(ids, MAX_RESULTS);
  const issues = [];
  const batches = chunkArray(chunks, 5);
  for (const batch of batches) {
    try {
      const promises = batch.map(async (issueKeys) => {
        const jql = `key in (${issueKeys.join(",")})`;

        const queryParams = new URLSearchParams({
          jql: jql,
          fields: fields.join(","),
          expand: "renderedFields",
          maxResults: "100",
        });
        const response = await getIssues(queryParams.toString());
        return response.issues;
      });
      const batchResult = await Promise.all(promises);
      issues.push(batchResult);
    } catch (err) {
      console.log("failed to fetch some issues ");
    }
  }

  return issues.flat(2);
}

export async function getIssues(queryParams: string) {
  const result = await AP.request(`/rest/api/2/search?${queryParams.toString()}`);
  return JSON.parse(result.body) as { issues: JiraIssue[]; total: number };
}

export async function getProject(projectIdOrKey: string) {
  try {
    const result = await AP.request(`/rest/api/3/project/${projectIdOrKey}`);
    return JSON.parse(result.body) as JiraProject;
  } catch (e) {
    console.error("Failed to fetch project");
  }
}

const getIssueType = (issue: JiraIssue) => {
  return issue.fields.issuetype?.id;
};

const getIssueLabels = (issue: JiraIssue) => {
  return issue.fields.labels || [];
};

const getIssueComponents = (issue: JiraIssue) => {
  return issue.fields.components?.map((component) => component.id) || [];
};

const getEpic = (issue: JiraIssue) => {
  return issue.fields.parent?.key;
};

const getReporter = (issue: JiraIssue) => {
  return issue.fields.reporter?.accountId;
};

const getPriority = (issue: JiraIssue) => {
  return issue.fields.priority?.id;
};

interface GetIssuesByReferenceProps {
  referenceIssue: JiraIssue;
  estimationField: string;
  estimate: string;
  additionalUserQuery?: string;
}

export async function getIssuesByReference({
  referenceIssue,
  estimationField,
  estimate,
  additionalUserQuery,
}: GetIssuesByReferenceProps) {
  const {
    fields: { project },
  } = referenceIssue;
  if (!project) throw new Error("Missing project information");

  const jql = [
    `project = "${project.name}"`,
    `statusCategory = "Done"`,
    `${estimationField} = "${estimate}"`,
    additionalUserQuery,
  ]
    .filter(Boolean)
    .join(" AND ");
  const fields = [
    // Sorting and displayed fields
    "priority",
    "issuetype",
    // Sorting fields
    "labels",
    "components",
    "parent",
    "reporter",
    //  Displayed fields
    "summary",
    "assignee",
  ].join(",");
  const queryParams = new URLSearchParams({
    jql: jql,
    fields: fields,
    maxResults: String(MAX_REFERENCE_ISSUES),
  });

  try {
    const { issues } = await getIssues(queryParams.toString());

    const referenceIssueType = getIssueType(referenceIssue);
    const referenceIssueLabels = getIssueLabels(referenceIssue);
    const referenceIssueComponents = getIssueComponents(referenceIssue);
    const referenceIssueEpic = getEpic(referenceIssue);
    const referenceIssueReporter = getReporter(referenceIssue);
    const referenceIssuePriority = getPriority(referenceIssue);
    // Most similar issues first
    const sortedIssues = sortBy(issues, (issue) => {
      const issueTypeValue = Number(getIssueType(issue) == referenceIssueType) << 5;
      const labelsValue = Number(getIssueLabels(issue).some((label) => referenceIssueLabels.includes(label))) << 4;
      const componentValue = Number(getIssueComponents(issue).some((id) => referenceIssueComponents.includes(id))) << 3;
      const epicValue = Number(getEpic(issue) == referenceIssueEpic) << 2;
      const reporterValue = Number(getReporter(issue) == referenceIssueReporter) << 1;
      const priorityValue = Number(getPriority(issue) == referenceIssuePriority);
      const sortScore = issueTypeValue + labelsValue + componentValue + epicValue + reporterValue + priorityValue;
      return -sortScore;
    });

    return sortedIssues;
  } catch (err) {
    throw new JiraApiError(err, Object.keys(fields));
  }
}

export async function getJiraStatuses() {
  try {
    const result = await AP.request(`/rest/api/3/status`);
    const statuses = JSON.parse(result.body) as JiraStatus[];

    return statuses.filter(
      (currentValue, index, statusFieldValues) =>
        statusFieldValues.findIndex((t) => t.name === currentValue.name) === index,
    );
  } catch (err) {
    console.error("Error while fetching global Jira statuses", err);

    return [];
  }
}

export async function validateJQL(query: string) {
  try {
    const result = await AP.request({
      url: "/rest/api/3/jql/parse?validation=strict",
      type: "POST",
      data: JSON.stringify({ queries: [query] }),
      contentType: "application/json",
    });
    const data = JSON.parse(result.body) as JiraJqlValidation;
    return data.queries[0];
  } catch (err) {
    console.error("Failed to validate JQL", err);
    return null;
  }
}

async function getEpicDetails(epicId: string) {
  try {
    const result = await AP.request(`/rest/agile/1.0/epic/${epicId}`);
    return JSON.parse(result.body) as EpicDetails;
  } catch (err) {
    console.error(`Error while fetching epic details for epic with id - ${epicId}`);
    return undefined;
  }
}

export async function getEpicsDetails(epicsIds: string[]): Promise<(EpicDetails | undefined)[]> {
  return Promise.all(epicsIds.map((epicId) => getEpicDetails(epicId)));
}

export async function getEpicsInBatches(epicsIds: string[], batchSize = 10) {
  const batches = chunkArray(epicsIds, batchSize);
  const epicsDetails = [];
  for (const batch of batches) {
    const result = await getEpicsDetails(batch);
    epicsDetails.push(...result);
  }
  return epicsDetails.filter(Boolean) as EpicDetails[];
}

export async function getProjectProperty<T>(projectIdOrKey: string, propertyKey: string, defaultValue: T) {
  try {
    const result = await AP.request({
      url: `/rest/api/3/project/${projectIdOrKey}/properties/${propertyKey}`,
      type: "GET",
      contentType: "application/json",
    });
    const property = JSON.parse(result.body) as {
      key: string;
      value: T;
    };
    return property.value;
  } catch {
    return setProjectProperty<T>(projectIdOrKey, propertyKey, defaultValue);
  }
}

export async function setProjectProperty<T>(projectIdOrKey: string, propertyKey: string, data: T) {
  return AP.request({
    url: `/rest/api/3/project/${projectIdOrKey}/properties/${propertyKey}`,
    type: "PUT",
    data: JSON.stringify(data),
    contentType: "application/json",
  }).then(() => data);
}

interface FieldUpdateOperation {
  add?: unknown;
  copy?: unknown;
  edit?: unknown;
  remove?: unknown;
  set?: unknown;
}

export type IssueUpdate = Record<string, FieldUpdateOperation[]>;

export async function updateIssue(issueIdOrKey: string, update: IssueUpdate) {
  return AP.request({
    url: `/rest/api/3/issue/${issueIdOrKey}`,
    type: "PUT",
    data: JSON.stringify({ update }),
    contentType: "application/json",
  }).catch((err) => {
    throw new JiraApiError(err, Object.keys(update));
  });
}

export async function getIssueTransitions(issueIdOrKey: string) {
  try {
    const response = await AP.request(`/rest/api/2/issue/${issueIdOrKey}/transitions`);
    const result = JSON.parse(response.body) as { transitions: IssueTransition[] };
    return result.transitions;
  } catch (error) {
    console.error(`Failed to fetch issue transitions for issueKey: ${issueIdOrKey}`, getErrorMessage(error));
    return [];
  }
}

export async function transitionIssue(issueIdOrKey: string, transitionId: string) {
  return AP.request({
    url: `/rest/api/3/issue/${issueIdOrKey}/transitions`,
    type: "POST",
    data: JSON.stringify({ transition: { id: transitionId } }),
    contentType: "application/json",
  }).catch((err) => {
    throw new JiraApiError(err);
  });
}

export async function moveIssuesToSprint(sprintId: string, issuesIdsOrKeys: string[]) {
  return AP.request({
    url: `/rest/agile/1.0/sprint/${sprintId}/issue`,
    type: "POST",
    data: JSON.stringify({ issues: issuesIdsOrKeys }),
    contentType: "application/json",
  }).catch((err) => {
    throw new JiraApiError(err);
  });
}

export async function getApproximateLicenseCount() {
  try {
    const result = await AP.request("/license/approximateLicenseCount/product/jira-software");
    const { value } = JSON.parse(result.body) as { value: string };
    return value;
  } catch {
    return undefined;
  }
}
